<template>
  <div class="workOrderConsumable">
    <!-- <div class="search">
      <v-search v-model="keyWords" @onSearch="onSearch"></v-search>
    </div> -->
    <div class="content">
      <div class="list">
        <div class="title">
          <div class="item">
            <span></span>
            <span>名称</span>
            <span>计量单位</span>
            <span>使用数量</span>
          </div>
          <div class="line">
            <div class="item"></div>
          </div>
        </div>
        <div class="list-content">
          <div
            class="title"
            v-for="(item, index) in materialsUseds"
            :key="index"
          >
            <div class="item">
              <div
                @click="handelClick($event, `${index}_ref`, item)"
                class="icon-img"
              >
                <img
                  :class="['icon', { active: item.isShow }]"
                  src="./img/sub-icon.png"
                  alt=""
                />
              </div>
              <div class="swipe">
                <van-swipe-cell disabled :ref="`${index}_ref`">
                  <div class="input">
                    <v-input class="input-item" v-model="item.name"></v-input>
                  </div>
                  <div class="input">
                    <v-input class="input-item" v-model="item.sku"></v-input>
                  </div>
                  <div class="stepper">
                    <v-stepper :min="1" v-model="item.quantity"></v-stepper>
                  </div>
                  <template #right>
                    <div class="right_remove" @click="handelRemove(index)">
                      删除
                    </div>
                  </template>
                </van-swipe-cell>
              </div>
            </div>
            <div class="line">
              <div class="item"></div>
            </div>
          </div>

          <div class="add" @click="add">
            <img src="./img/add-icon.png" alt="" />
            <span>添加耗材</span>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="confirm-btn" @click="confirm">确认</div>
    </div>
  </div>
</template>

<script>
import { getMaterials } from "./api.js";
export default {
  name: "workOrderConsumable",
  components: {},
  props: {},
  data() {
    return {
      materialsUseds: [
        {
          name: "",
          sku: "",
          quantity: null,
          isShow: false,
        },
      ],
      curIndex: 0,
      keyWords: "",
      num: "",
      listData: [],
      workTypeId: "",
      isShow: false,
    };
  },
  created() {
    this.getMaterials();
  },
  mounted() {
    const { workTypeId } = this.$route.query;
    if (workTypeId) {
      this.workTypeId = workTypeId;
    }
  },
  methods: {
    add() {
      const params = {
        name: "",
        sku: "",
        quantity: null,
        isShow: false,
      };
      this.materialsUseds.push(params);
    },
    handelRemove(index) {
      this.materialsUseds.splice(index, 1);
    },
    handelClick(e, node, item) {
      e.stopPropagation();
      item.isShow = !item.isShow;
      if (item.isShow) {
        this.$refs[node][0].open("right");
      } else {
        this.$refs[node][0].close("right");
      }
    },
    //确认confirm
    confirm() {
      const res = this.materialsUseds.map((item) => {
        const { isShow, ...reset } = item;
        return reset;
      });
      const allFieldsHaveValues = res.every((item) => item.name && item.sku);
      if (allFieldsHaveValues) {
        this.$router.push({
          name: "workOrderServeDetail",
          query: {
            list: encodeURIComponent(JSON.stringify(res)),
          },
        });
      } else {
        this.$toast({
          message: "请完善耗材信息！",
          duration: 500,
        });
      }
    },
    getMaterials(name) {
      this.$axios
        .get(getMaterials, {
          params: {
            name,
          },
        })
        .then((res) => {
          if (res.code == 200) {
            const { data } = res;
            data.forEach((item) => {
              item.quantity = 1;
              item.materialsId = item.id;
              item.id = "";
              item.workId = this.workTypeId;
              item.isSelect = false;
            });
            this.listData = data;
            console.log("res----->", res);
          }
        });
    },
    handelSelect(item) {
      item.isSelect = !item.isSelect;
    },
    onSearch(value) {
      this.getMaterials(this.keyWords);
      console.log("value----->", this.keyWords);
    },
  },
};
</script>

<style scoped lang="less">
.workOrderConsumable {
  width: 100%;
  min-height: 100%;
  background: #f8f8f8;
  .search {
    z-index: 9;
    width: 100%;
    height: 112px;
    background: #ffffff;
    position: sticky;
    top: 0;
    left: 0;
    ::v-deep .v-search {
      height: 100%;
      .van-search {
        height: 100%;
        padding: 22px 48px 22px 40px;
        .van-search__content {
          border-radius: 1000px;
        }
        .van-search__action {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #fe4416;
          line-height: 40px;
        }
      }
    }
  }
  .content {
    box-sizing: border-box;
    padding: 20px 30px 225px 30px;
    .list {
      width: 100%;
      height: auto;
      box-sizing: border-box;
      background: #ffffff;
      border-radius: 16px;
      box-sizing: border-box;

      padding-bottom: 32px;
      .title {
        padding-right: 30px;
        box-sizing: border-box;
        .item {
          display: flex;

          span {
            padding: 18px 0 12px 0;
            font-size: 28px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: rgba(51, 51, 51, 0.85);
            line-height: 56px;
            text-align: center;
            display: inline-block;
            width: calc((100% - 15%) / 3);
            &:first-child {
              width: 15%;
            }
          }
          div {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            .input-item {
              width: 100%;
              ::v-deep .van-cell {
                .van-cell__value {
                  .van-field__body {
                    .van-field__control {
                      background-color: #fafafa !important;
                    }
                  }
                }
              }
            }
          }
          .icon-img {
            width: 15%;
            .icon {
              width: 36px;
              height: 36px;
              transform: rotate(0);
              transition: all 0.5s;
              &.active {
                transform: rotate(90deg);
              }
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
          .swipe {
            width: 85%;
            box-sizing: border-box;
            ::v-deep .van-swipe-cell__wrapper {
              display: flex;
              div {
                flex: 1;
                // &:last-child {
                //   margin-right: 30px;
                //   box-sizing: border-box;
                // }
                // &:nth-child(1) {
                //   flex: 2;
                // }
                // &:nth-child(3) {
                //   flex: 1.5;
                // }
              }
            }
          }
          .stepper {
            // ::v-deep .van-stepper {
            //   .van-stepper__minus {
            //     width: 46px;
            //     height: 50px;
            //     background: #f3f6f8;
            //     border-radius: 12px 0px 0px 12px;
            //     border: 2px solid #d8d8d8;
            //     margin-right: -2px;
            //     box-sizing: border-box;
            //   }
            //   .van-stepper__input {
            //     margin: 0;
            //     width: 98px;
            //     height: 50px;
            //     background: #ffffff;
            //     border-top: 2px solid #d8d8d8;
            //     border-bottom: 2px solid #d8d8d8;
            //     box-sizing: border-box;
            //   }
            //   .van-stepper__plus {
            //     width: 46px;
            //     height: 50px;
            //     background: #f3f6f8;
            //     border-radius: 0px 12px 12px 0;
            //     margin-left: -2px;
            //     border: 2px solid #d8d8d8;
            //     box-sizing: border-box;
            //   }
            // }
          }
        }
        .line {
          width: 100%;
          box-sizing: border-box;
          padding: 0 28px 0 30px;
          .item {
            width: 100%;
            height: 2px;
            background-color: rgba(0, 0, 0, 0.06);
          }
        }
      }
      .add {
        margin-top: 28px;
        box-sizing: border-box;
        display: flex;
        width: 100%;
        justify-content: center;
        img {
          margin-right: 18px;
          width: 42px;
          height: 42px;
        }
        span {
          font-size: 32px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          color: #fe4416;
          line-height: 44px;
        }
      }
      .right_remove {
        margin-left: 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        background: #fe4416;
        font-size: 28px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        padding: 0 22px;
      }
    }

    // .list {
    //   min-height: 264px;
    //   background: #ffffff;
    //   border-radius: 16px;
    //   box-sizing: border-box;
    //   padding: 20px 28px 26px 40px;
    //   display: flex;
    //   flex-direction: column;
    //   border: 2px solid transparent;
    //   justify-content: space-between;
    //   &:not(:last-child) {
    //     margin-bottom: 20px;
    //   }
    //   &.active {
    //     border: 2px solid #fe4416;
    //     box-sizing: border-box;
    //   }
    //   .item {
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;
    //     box-sizing: border-box;
    //     .item-l {
    //       width: 120px;
    //       flex-shrink: 0;
    //       font-size: 28px;
    //       font-family: PingFangSC-Medium, PingFang SC;
    //       font-weight: 500;
    //       color: rgba(51, 51, 51, 0.85);
    //       line-height: 56px;
    //     }
    //     .item-r {
    //       box-sizing: border-box;
    //       font-size: 24px;
    //       font-family: PingFangSC-Regular, PingFang SC;
    //       font-weight: 400;
    //       text-overflow: -o-ellipsis-lastline;
    //       overflow: hidden; //溢出内容隐藏
    //       text-overflow: ellipsis; //文本溢出部分用省略号表示
    //       display: -webkit-box; //特别显示模式
    //       -webkit-line-clamp: 2; //行数
    //       line-clamp: 2;
    //       -webkit-box-orient: vertical; //盒子中内容竖直排列

    //       color: rgba(51, 51, 51, 0.85);
    //       line-height: 56px;
    //       ::v-deep .van-stepper {
    //         .van-stepper__minus {
    //           width: 46px;
    //           height: 50px;
    //           background: #f3f6f8;
    //           border-radius: 12px 0px 0px 12px;
    //           border: 2px solid #d8d8d8;
    //           margin-right: -2px;
    //           box-sizing: border-box;
    //         }
    //         .van-stepper__input {
    //           margin: 0;
    //           width: 98px;
    //           height: 50px;
    //           background: #ffffff;
    //           border-top: 2px solid #d8d8d8;
    //           border-bottom: 2px solid #d8d8d8;
    //           box-sizing: border-box;
    //         }
    //         .van-stepper__plus {
    //           width: 46px;
    //           height: 50px;
    //           background: #f3f6f8;
    //           border-radius: 0px 12px 12px 0;
    //           margin-left: -2px;
    //           border: 2px solid #d8d8d8;
    //           box-sizing: border-box;
    //         }
    //       }
    //     }
    //     .unit {
    //       font-size: 28px;
    //     }
    //   }
    // }
  }
  .footer {
    width: 100%;
    z-index: 10;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    background: #ffffff;
    padding: 48px 36px 90px 0;
    .confirm-btn {
      text-align: center;
      width: 226px;
      height: 74px;
      background: #fe4416;
      border-radius: 44px;
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 74px;
    }
  }
}
</style>
